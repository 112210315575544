<template>
  <div class="container">
    <el-dialog
      title="创建酒店"
      :visible.sync="hotelVisible"
      width="40%"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div>
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banbentwoItem">
            <el-form-item label="酒店名称" prop="hotelName">
              <el-input
                ref="hotelName"
                v-model="formInline.hotelName"
                placeholder="请输入酒店名称"
                maxlength="20"
                type="text"
              ></el-input>
            </el-form-item>
          </div>

          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="欢迎词" prop="welcome">
                <el-input
                  ref="welcome"
                  maxlength="255"
                  v-model="formInline.welcome"
                  placeholder="欢迎光临"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banbentwo">
            <div class="banbentwoItem">
              <el-form-item label="欢迎语推送延时(秒)" prop="delay">
                <el-input
                  v-model="formInline.delay"
                  placeholder="请输入欢迎语推送延时"
                  type="text"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="banben">
            <div class="bandanItem">
              <el-form-item label="长期" prop="validDate">
                <el-switch
                  v-model="cq"
                  @change="handleChange"
                  active-value="0"
                  inactive-value="1"
                ></el-switch>
              </el-form-item>
            </div>
            <div class="bandanItem">
              <el-form-item label="试用期到期" prop="validDate">
                <el-date-picker
                  v-model="formInline.validDate"
                  type="date"
                  placeholder="选择试用期到期日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <!-- <div class="bandanItem">
              <el-form-item label="排序" prop="dispOrder">
                <el-input
                  ref="dispOrder"
                  maxlength="20"
                  v-model="formInline.dispOrder"
                  placeholder="请输入排序"
                  type="text"
                ></el-input>
              </el-form-item>
            </div> -->
          </div>
          <el-form-item label="音乐地址" prop="musicUrl">
            <el-input
              ref="code"
              v-model="formInline.musicUrl"
              placeholder="请输入音乐地址"
              maxlength="255"
              type="text"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="酒店公钥" prop="apiKey">
            <el-input
              ref="code"
              v-model="formInline.apiKey"
              placeholder="请输入酒店公钥"
              maxlength="20"
              type="text"
            ></el-input>
          </el-form-item> -->
          <!-- <div class="banbentwo">
            <div class="banbentwoItem"> -->
          <el-form-item label="备注">
            <el-input
              ref="remark"
              maxlength="100"
              v-model="formInline.remark"
              placeholder="请输入备注"
              type="textarea"
            ></el-input>
          </el-form-item>
          <!-- </div>
          </div> -->
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      hotelVisible: true,
      user: {},
      cq: "1",
      formInline: {
        delay: 0,
      },
      selectHotel: {},
      hotel: null,
      rules: {
        hotelName: [
          {
            required: true,
            message: "请输入酒店名称，不能为空",
            trigger: "blur",
          },
        ],
        welcome: [
          {
            required: true,
            message: "请输入欢迎词，不能为空",
            trigger: "blur",
          },
        ],
        delay: [
          {
            required: true,
            message: "请输入欢迎语推送延时，不能为空",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注，不能为空",
            trigger: "blur",
          },
        ],
        dispOrder: [
          {
            required: true,
            message: "请输入排序，不能为空",
            trigger: "blur",
          },
        ],
        // musicUrl: [
        //   {
        //     required: true,
        //     message: "请输入音乐地址，不能为空",
        //     trigger: "blur",
        //   },
        // ],
        validDate: [
          {
            required: true,
            message: "请选择试用期到期日期，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  created() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.hotelList = JSON.parse(window.localStorage.getItem("hotelList"));
    this.formInline = {
      delay: 0,
      validDate: "",
      welcome: "欢迎光临！",
    };
  },
  methods: {
    handleChange(val) {
      if (val == 1) {
        this.formInline.validDate = "";
      } else {
        this.formInline.validDate = "2099-12-31";
      }
    },
    // 选择酒店
    createStorage(formData) {
      // 表单验证-----------
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.formInline.delay = Number(this.formInline.delay);
          let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
          postRequest("insertSysHotel", this.formInline).then((res) => {
            this.$nextTick(() => {
              loadingInstance.close();
            }); //不回调
            if (res.status == 200) {
              this.$message({
                duration: 5000,
                message: "新增成功!",
                type: "success",
              });
              this.selectHotel.hotelId = res.data.id;
              this.selectHotel.hotelName = res.data.hotelName;
              this.getHotel();
            } else {
              this.$message({
                duration: 5000,
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    getHotel() {
      this.selectHotel.userCode = this.user.userCode;
      this.selectHotel.userName = this.user.userName;
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/selectHotel", this.selectHotel).then((res) => {
        if (res.status == 200) {
          localStorage.setItem("hotelInfor", JSON.stringify(res.data));
          localStorage.setItem("findPage", 0);
          this.$store.state.roomList = null;
          this.getRoom();
          if (this.$store.state.roomList.length > 0) {
            this.$nextTick(() => {
              loadingInstance.close();
            });
          }
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    getRoom() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              console.log(item2.valueCode);
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.$store.state.roomList = res.data;

          this.$router.push("/roomControl");
          //
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    closeDialog() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.el-radio__inner {
  height: 24px !important;
  width: 24px !important;
}
.el-radio__inner::after {
  height: 10px;
  width: 10px;
}
input {
  opacity: 0.65;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.bandanItem {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 74px;
  margin-bottom: 16px;
}
.bandanItem > div {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.bandanItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.bandanItem > .el-date-picker {
  width: 100%;
  height: 40px;
}
.bandanItem > select {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
</style>
